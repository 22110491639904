<template>
    <div class="twod_warning" style="height: 100vh; overflow-y: scroll;">
        <Sticky class="normal-box pt-3 px-2">
          <div style="border-radius: 10px; background-color: rgb(63, 175, 74); box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" class="col-12 mx-auto py-3">
              <div class="d-flex justify-content-between">
                  <div class="col-5 p-0">
                      <button class="btn-danger" style="width: 100%; border-radius: 7px;" @click="$playSound(); showGroupBox()">
                          {{ $t('lottery.quick_bet') }}
                      </button>
                  </div>
                  <div class="col-7 p-0 pl-2">
                      <!-- {{ $store.state.language === "en" ? "Amount" : "ငွေပမာဏ" }} -->
                      <div class="form-group mb-0">
                              <input
                                  type="number"
                                  ref="amount"
                                  v-model="numberAmount"
                                  class="form-control amount mt-1"
                                  placeholder="100.00 MMK"
                                  style="height: 31px; border-radius: 8px; border: none;"
                              />
                      </div>
                  </div>
              </div>
              <hr class="mt-3 mb-2">
              <div class="d-flex justify-content-between">
                  <div>
                    <b>
                        <i class="fas fa-wallet my-auto mr-1" style="color: #000000"></i> :
                        <span class="text-dark">
                          {{
                              Number($store.state.amount).toLocaleString()
                          }} MMK
                        </span>
                    </b>
                  </div>
                  
                  <div class="text-danger">
                    <i class="fas fa-clock" style="color: #000000"></i> <span class="text-dark"> {{ $t('lottery.bet_close') }} : </span><b class="text-dark">{{betClose()}}</b>
                  </div>
              </div>
              <hr class="mt-2 mb-3">
              <div class="d-flex justify-content-between">
                  <div class="d-flex">
                        <span class="px-2 my-auto py-1 number-reverse" :class="isReverse ? 'active' : ''" @click="$playSound(); isReverse = !isReverse">R</span>
                  </div>
                  <div class="col-3 p-0">
                    <button class="btn-danger" style="width: 100%; border-radius: 7px;"  @click="$router.push('/user/dream-books?type=2d')">
                      <i class="fas fa-book text-white mr-2"></i>
                        {{ $t('lottery.dream') }}
                    </button>
                  </div>
                  <div class="col-5 p-0">
                      <button class="btn-danger" style="width: 100%; border-radius: 7px;"  @click="$playSound(); preBet()">
                          <div v-if="!isBet">
                            {{ $t('lottery.bet') }}
                          </div>
                      </button>
                  </div>
              </div>
              <div class="d-flex justify-content-between pt-3">
                  <div class="col-6 d-flex justify-content-start px-0">
                        <span class="text-dark text-sm font-weight-400 mr-2" @click="$playSound(); openColorsModal()">{{ $t('lottery.meaning_color') }}</span>
                        <span class="badge badge-dark text-white" style="background: #000000"><i class="fa fa-info" @click="$playSound(); openColorsModal()"></i> </span>
                  </div>
                  <div class="col-6 d-flex justify-content-end px-0">
                    <i class="fa fa-redo my-auto text-lg" style="color: #000000" @click="$playSound(); removeHistory()"></i>
                    <span class="my-auto text-dark text-sm font-weight-400 ml-2" @click="$playSound(); removeHistory()">{{ $t('lottery.all_clear') }}</span>
                  </div>
              </div>
          </div>
        </Sticky>

        <div class="mt-3 mx-auto px-2" style="width: 100%; gap: 7px; display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;">
          <div class="number-paginate px-2 py-1" :class="{'active' : drawTime === option.data, 'disabled' : option.isDisable}" @click="$playSound(); !option.isDisable ? drawTime = option.data : ''" v-for="(option, index) in options" :key="index">{{option.isDisable ? option.disLabel : option.label}}</div>
          <!-- <div class="number-paginate px-2 py-1" :class="drawTime === '12:00' ? 'active' : ''" @click="drawTime = '12:00'">12:01 PM</div>
          <div class="number-paginate px-2 py-1" :class="drawTime === '2:45' ? 'active' : ''" @click="drawTime = '2:45'">2:00 PM</div>
          <div class="number-paginate px-2 py-1" :class="drawTime === '4:30' ? 'active' : ''" @click="drawTime = '4:30'">4:30 PM</div> -->
      </div>
        <div class="d-flex p-0 flex-wrap main-wrapper" style="margin-top: -0.5rem; margin-bottom: 4rem;">
            <div class="col-lg-4 col-md-6 px-2 boxes pb-5 mb-5" style="min-height:100vh; height: auto;">
                <Table @numbers="getNumbers" :drawTime="drawTime" :twod="twod" :isTwod="true" :on-my-event="myEventSource" :allNumbers="allNumbers"/>
            </div>

            <div class="col-lg-4 col-md-6 col-12 d-flex flex-column px-2 boxes pb-5 mb-5" ref="groupBox" v-if="drawTime">
              <GroupBox @numbers="getGroupNumbers" @quickNumbers="getQuickNumbers" :allNumbers="allNumbers" class="mt-2" :on-my-event="myEventSource" :drawTime="drawTime"/>
          </div>

          <van-overlay v-show="noticeText">
              <div class="wrapper w-100 d-flex flex-column">
                <van-notice-bar class="w-100" text="ယနေ့အတွက် နှလုံးထီ ထိုးခြင်း ပိတ်ပါပြီ ။" left-icon="volume-o" scrollable color="#000000" background="rgb(116, 239, 128)"/>
                  <div class="pt-3 mx-auto">
                    <button class="btn btn-danger mb-4" @click="$playSound(); $router.go(-1)">
                      {{ $store.state.language === "en" ? "Back To Home!" : "ရှေ့သို့ပြန်သွားမည်။" }}
                    </button>
                  </div>
              </div>
          </van-overlay>

          <!-- Preview Voucher -->
          <div class="col-lg-4 col-md-6 px-2">
              <div class="row">
                  <!-- === only amount === -->
                  <div class="col-md-4 cart-voucher">
                      <div
                          class="modal fade"
                          id="mobile-voucher"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="modal-form"
                          aria-hidden="true"
                      >
                          <div
                              class="
                                  modal-dialog
                                  modal-
                                  modal-dialog-centered
                                  modal-sm
                              "
                              role="document"
                          >
                              <div class="modal-content">
                                  <div
                                      class="text-center modal-cross"
                                      @click="closeModal"
                                  >
                                      X
                                  </div>
                                  <div class="modal-body p-0">
                                      <PreviewVoucher
                                          :remark="remark"
                                          v-if="isMobile"
                                          :datas="datas"
                                          @remove="removeHistory"
                                          :isTwod="true"
                                          :drawTime="drawTime"
                                      />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

        <div class="col-lg-4 col-md-6 px-2">
            <div class="row">
                <!-- === only amount === -->
                <div class="col-md-4 cart-voucher">
                    <div
                        class="modal fade"
                        id="colors-modal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="modal-form"
                        aria-hidden="true"
                    >
                        <div
                            class="
                                modal-dialog
                                modal-
                                modal-dialog-centered
                                modal-sm
                            "
                            role="document"
                        >
                            <div class="modal-content">
                                <div class="modal-heading bg-danger py-3" style="border-top-right-radius:0.4375rem; border-top-left-radius:0.4375rem">
                                      <h4 class="text-center text-white">
                                        အရောင် အဓိပ္ပါယ် <br> 100% ဖြစ်သောအခါ ထီနံပါတ်များကို ပိတ်ပါမည်။
                                      </h4>
                                    </div>
                                <div class="modal-body px-5 d-flex py-3">
                                    <ul>
                                      <li class="my-2"> 
                                        <div class="d-flex">
                                          <div class="my-auto mx-2" style="border:1px solid #d5d5d5; background-color: green; width:20px; height: 20px; border-radius: 50%;"></div> 50% အောက်
                                        </div>
                                      </li>
                                      <li class="my-2"> 
                                        <div class="d-flex">
                                          <div class="my-auto mx-2" style="border:1px solid #d5d5d5; background-color: yellow; width:20px; height: 20px; border-radius: 50%;"></div> 50% မှ 75% အတွင်း 
                                        </div>
                                      </li>
                                      <li class="my-2">
                                        <div class="d-flex">
                                          <div class="my-auto mx-2" style="border:1px solid #d5d5d5; background-color: red; width:20px; height: 20px; border-radius: 50%;"></div> 90% အထက် 
                                        </div>
                                      </li>
                                      <li class="my-2">
                                        <div class="d-flex">
                                          <div class="my-auto mx-2" style="border:1px solid #d5d5d5; background-color: #7c7676; width:20px; height: 20px; border-radius: 50%;"></div> အပြည့် (100%)
                                        </div>
                                      </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <van-overlay :show="isLoading">
          <div class="wrapper" @click.stop>
            <van-loading type="spinner" color="#ffffff" class="mx-auto"/>
          </div>
        </van-overlay>
      
    </div>
</template>
<script>
import PreviewVoucher from '../../components/PreviewVoucher.vue';
import Table from '../../components/Table.vue';
import GroupBox from '../../components/GroupBox.vue';
import NormalBox from '../../components/NormalBox.vue';
import { NoticeBar, Sticky, Dialog } from 'vant';
import createDateWithTimeZone from "../../../../libs/createDateWithTimeZone"
import { mapActions } from 'vuex';
import { newEventSource } from 'vue-parent-emit';

export default {
  components: {
    PreviewVoucher,
    Table,
    GroupBox,
    NormalBox,
    NoticeBar,
    Sticky
  },
  data() {
    return {
      myEventSource: newEventSource(),
      drawTime: null,
      datas: [],
      selected_data: {},
      isMobile: false,
      remark: '',
      noticeText: null,
      interval: null,
      options: [],
      isGroup: false,
      isReverse: false,
      numberAmount: null,
      allNumbers: this.$store.state.twod_numbers,
      errors: {},
      numbers: [],
      isBet: false,
      betCloseTime: {},
      voucherId: this.slipId(),
      twod: {
        latest_bet_m: "11:45",
        latest_bet_e: "2:35",
        latest_bet_m_mid: "10:35",
        latest_bet_e_mid: "3:50",
      },
      voucherLength: null,
      isLoading: false,
    };
  },

  methods: {
    ...mapActions(['fetchUser', 'twodData']),
    getQuickNumbers(value){
      this.datas = value;
      $("#mobile-voucher").modal("show");
    },
    slipId() {
      return this.voucherLength;
    },
    openColorsModal() {
      $('#colors-modal').modal('show');
    },
    async preBet() {
    try {
      if (this.isBet) {
          return false;
      }
      if (this.voucherId === this.slipId()) {
          return false;
      }
      if (this.datas.length == 0) {
          const dataError = this.$store.state.language === "en"
                            ? "Please pick some numbers!"
                            : "နံပါတ်အချို့ ရွေးပေးပါ။";
          this.dialog(dataError);
          return false;
      }
      if (!this.numberAmount) {
          const dataError = this.$store.state.language === "en" ? 'Please enter amount' : 'ငွေပမာဏထည့်ပေးပါ။';
          this.dialog(dataError);
          return false;
      }
      if (this.numberAmount < 100) {
          this.errors.amount = this.$store.state.language === 'en'
          ? 'Please enter at least 100 MMK'
          : 'ငွေပမာဏအနည်းဆုံး ၁၀၀ ထည့်ပေးပါ။';

          const dataError = this.$store.state.language === "en" ? 'Please enter at least 100 MMK' : 'ငွေပမာဏအနည်းဆုံး ၁၀၀ ထည့်ပေးပါ။';
          this.dialog(dataError);
          return false;
      }

      if(this.isReverse){
       // Step 1: Reverse numbers in selected data (`datas`)
        const reversedNumbers = this.datas.map(item => ({
          ...item,
          number: String(item.number).split('').reverse().join('') // Reverse the number
        }));

        // Step 2: Filter `allNumbers` based on reversed numbers
        const filteredFromAllNumbers = this.allNumbers.filter(item =>
          reversedNumbers.some(reversedItem => reversedItem.number == item.number)
        );

        // Step 3: Merge filtered data with selected data (`datas`)
        const combinedData = [
          ...this.datas,
          ...filteredFromAllNumbers
        ];

        // Step 4: Remove duplicates based on `number`
        this.datas = combinedData.reduce((acc, current) => {
          const x = acc.find(item => item.number == current.number);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);

          // let draw = '4:30';
          // if(this.$route.query.draw === 'm') {
          //   draw = '12:00';
          // }
          this.datas = this.datas.map(item => ({
              ...item,          // Spread the existing properties of the object
              solo_amount: this.numberAmount,  // Add or update the `solo_amount` property
              draw: this.drawTime
          }));
      }
     
    $("#mobile-voucher").modal("show");
    } catch (error) {
        console.log(error);
    }
  },

  betClose() {
    if (this.drawTime === '12:00') {
      const newBetTimeArray = this.$store.state.twodData.latest_bet_m_mid.toString(10).split(":").map((t) => t);

      this.betCloseTime.hour = newBetTimeArray[0].toString();
      this.betCloseTime.minute = newBetTimeArray[1].toString();

      this.betCloseTime.isMorning = true;
      return `${this.$store.state.twodData.latest_bet_m} AM`;
    } else if (this.drawTime === '10:45') {
      const newBetTimeArray = this.$store.state.twodData.latest_bet_m_mid.toString(10).split(":").map((t) => t);

      this.betCloseTime.hour = newBetTimeArray[0].toString();
      this.betCloseTime.minute = newBetTimeArray[1].toString();
      this.betCloseTime.isMorning = true;

      return `${this.$store.state.twodData.latest_bet_m_mid} AM`;
    } else if (this.drawTime === '4:30') {
      const newBetTimeArray = this.$store.state.twodData.latest_bet_m_mid.toString(10).split(":").map((t) => t);

      this.betCloseTime.hour = newBetTimeArray[0].toString();
      this.betCloseTime.minute =newBetTimeArray[1].toString();
      return `${this.$store.state.twodData.latest_bet_e_mid} PM`;
    }
    const newBetTimeArray = this.$store.state.twodData.latest_bet_m_mid.toString(10).split(":").map((t) => t);

    this.betCloseTime.hour = newBetTimeArray[0].toString();
    this.betCloseTime.minute = newBetTimeArray[1].toString();
    return `${this.$store.state.twodData.latest_bet_e} PM`;
  },

  async checkCurrentVouchers() {
    try {
      const res = await axios.post('/vouchers/check', {
        type: 'twod',
      },{
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              });
      this.voucherLength = res.data.voucherLength;
    } catch (error) {
      console.log(error);
    }
  },
  dialog(text) {
            Dialog.alert({
                message: text,
                confirmButtonText:
                    this.$store.state.language === "en"
                        ? "Go Back"
                        : "နောက်သို့ပြန်သွားမည်။",
            }).then(() => {
                // on close
            });
        },

    showGroupBox() {
      this.isGroup = !this.isGroup;
      $('#2d_group_box').modal('show');
    },
    async fetchTwoNumbers() {
      try {
        this.isLoading = true;
        const res = await axios.post('/twod_numbers', {
          limit: 'all',
          drawTime: this.drawTime
        },{
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
          });
        this.$store.commit('setTwodnumbers', res.data.data);
        this.allNumbers = res.data.data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },

    createDateWithTimeZone() {
      const apiUrl = 'https://api.yoeyar.vip/api/timezone/Asia/Yangon';

        // Make a GET request using the fetch function
        fetch(apiUrl)
            .then(response => {
            // Check if the request was successful (status code 2xx)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            // Parse the JSON response
            return response.json();
            })
            .then(data => {
            const newDateObject = new Date(data);
            console.log('newDateObject', newDateObject)
            return newDateObject;
            })
            .catch(error => {
            // Handle errors
            console.error('Error fetching data:', error);
            });
    },

   async drawOptionGenerator(){
        const currentTime = await createDateWithTimeZone();
        let closeTime = [];

        function clone(date) {
            return new Date(date.getTime());
        }
        
        const morning_mid = clone(currentTime);

        const morning = clone(currentTime);

        const evening_mid = clone(currentTime);

        const evening = clone(currentTime);

        closeTime = this.$store.state.twodData.latest_bet_m_mid.toString(10).split(":").map((t) => t);
        morning_mid.setHours(closeTime[0], closeTime[1]);

        closeTime = this.$store.state.twodData.latest_bet_m.toString(10).split(":").map((t) => t);
        morning.setHours(closeTime[0], closeTime[1]);

        closeTime = this.$store.state.twodData.latest_bet_e.toString(10).split(":").map((t) => t);
        evening_mid.setHours(closeTime[0], closeTime[1]);

        closeTime = this.$store.state.twodData.latest_bet_e_mid.toString(10).split(":").map((t) => t);
        evening.setHours(closeTime[0], closeTime[1]);

        console.log('morning_mid', morning_mid);
        console.log('morning', morning);
        console.log('evening_mid', evening_mid);
        console.log('evening', evening);
        console.log('currentTime', currentTime);
        console.log('test', currentTime < morning_mid);
        
        if(currentTime < morning_mid){
          if(!this.drawTime) this.drawTime = '10:45';
            this.options =[
                {
                    data: '10:45',
                    label: '9:30 AM',
                    isDisable: false,
                    
                },
                {
                    data: '12:00',
                    label: '12:01 PM',
                    isDisable: false,
                    
                },
                {
                    data: '2:45',
                    label: '2:00 PM',
                    isDisable: false,
                },
                {
                    data: '4:30',
                    label: '4:30 PM',
                    isDisable: false,
                    disLabel: '⛔ 4:30 PM',
                }
            ];
        } else if(currentTime < morning){
          if(!this.drawTime) this.drawTime = '12:00';
            this.options = [
                {
                    data: '10:45',
                    label: '9:30 AM',
                    isDisable: true,
                    disLabel: '⛔ 9:30 AM',
                },
                {
                    data: '12:00',
                    label: '12:01 PM',
                    isDisable: false,
                    
                },
                {
                    data: '2:45',
                    label: '2:00 PM',
                    isDisable: false,
                },
                {
                    data: '4:30',
                    label: '4:30 PM',
                    isDisable: false,
                }
            ];
        } else if(currentTime < evening_mid){
          if(!this.drawTime) this.drawTime = '2:45';
            this.options = [
                        {
                            data: '10:45',
                            label: '9:30 AM',
                            isDisable: true,
                            disLabel: '⛔ 9:30 AM',
                        },
                        {
                            data: '12:00',
                            label: '12:01 PM',
                            isDisable: true,
                            disLabel: '⛔ 12:01 PM',
                        },
                        {
                            data: '2:45',
                            label: '2:00 PM',
                            isDisable: false,
                        },
                        {
                            data: '4:30',
                            label: '4:30 PM',
                            isDisable: false,
                        }
                    ];
        } else if(currentTime < evening) {
          if(!this.drawTime) this.drawTime = '4:30';
          this.options= [
                        {
                            data: '10:45',
                            label: '9:30 AM',
                            isDisable: true,
                            disLabel: '⛔ 9:30 AM',
                        },
                        {
                            data: '12:00',
                            label: '12:01 PM',
                            isDisable: true,
                            disLabel: '⛔ 12:01 PM',
                        },
                        {
                            data: '2:45',
                            label: '2:00 PM',
                            isDisable: true,
                            disLabel: '⛔ 2:00 PM',
                        },
                        {
                            data: '4:30',
                            label: '4:30 PM',
                        }
          ];
        }

        if(currentTime > evening){
            return this.noticeText = this.$store.state.language === "en" ? '2D is closed for Today.' : 'ယနေ့အတွက် နှလုံးထီ ထိုးခြင်း ပိတ်ပါပြီ ။';
        }
    },

    closeModal() {
      $('#mobile-voucher').modal('hide');
      $('#colors-modal').modal('hide');
    },
    getRemark(value) {
      this.remark = value;
    },
    getNumbers(value) {
        for (let i = 0; i < this.allNumbers.length; i += 1) {
            if(value.findIndex(obj => obj.number === this.allNumbers[i].number)  >= 0){
                this.allNumbers[i].isSelect = true;
            } else {
                this.allNumbers[i].isSelect = false;
            }
        }
    this.datas = value;
    // let draw = '4:30';
    // if(this.$route.query.draw === 'm') {
    //   draw = '12:00';
    // }
    this.datas = this.datas.map(item => ({
          ...item,          // Spread the existing properties of the object
          solo_amount: this.numberAmount, // Add or update the `solo_amount` property
          draw: this.drawTime
      }));
    },
    getGroupNumbers(value) {
        for (let i = 0; i < this.allNumbers.length; i += 1) {
            this.allNumbers[i].isSelect = false;
        }
        for (let i = 0; i < value.length; i += 1) {
            this.allNumbers[this.allNumbers.findIndex(obj => obj.number === value[i].number)].isSelect = value[i].isSelect;
        }
    this.datas = value;
    this.datas = this.datas.map(item => ({
          ...item,          // Spread the existing properties of the object
          solo_amount: this.numberAmount, // Add or update the `solo_amount` property
          draw: this.drawTime
      }));
    },
    sendEvent() {
    this.myEventSource.emit();
  },
    async removeHistory() {
      this.datas = [];
      $("#mobile-voucher").modal("hide");
      this.numberAmount = null;
      this.sendEvent();
      this.allNumbers = this.allNumbers.map(item => ({
        ...item,
        isSelect: false
      }));
      await this.fetchTwoNumbers();
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
  unmounted() {
    clearInterval(this.interval);
  },
  watch: {
    drawTime() {
      this.fetchTwoNumbers();
    },
    numberAmount: {
        handler() {
          this.datas = this.datas.map(item => ({
            ...item,          // Spread the existing properties of the object
            solo_amount: this.numberAmount  // Add or update the `solo_amount` property
        }));
      },
      deep: true,
    },
  },
  async mounted() {
    this.twodData();
    const authUser = await this.fetchUser();
    if (!authUser) {
      this.$router.push('/auth/login');
    }
    if (window.innerWidth < 768) {
      this.isMobile = true;
    }
    if (this.$auth.check()) {
      this.$store.commit(
        'setAmount',
        parseInt(this.$store.state.authUser.amount, 10),
      );
    }
    await this.drawOptionGenerator();
    this.interval = setInterval(async() => await this.drawOptionGenerator(), 30000);
  },
};
</script>
<style scoped>
.btn-danger{
  background: #0050A7;
}
.number-paginate{
    border:2px solid #000000;
    border-radius: 10px;
    /* padding: 10px; */
    color: #000000;
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    text-wrap: nowrap;
}
.number-paginate.active{
    background: #000000;
    color: white;
}
.number-paginate.disabled{
    background: #D8D8D8;
    color: #8D8D8D;
    border:2px solid #D8D8D8;
}

.van-overlay {
  background: rgba(0, 0, 0, 0.85);
  z-index: 10000;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
.notice-bar{
  background: rgb(228, 87, 87);
  color: white;
  font-size: 1.2rem;
  height: 30px;
}
.input-group {
    border: 1px solid #cccccc;
}
.modal-cross {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding-top: 3px;
    margin-left: auto;
    color: white;
    cursor: pointer;
    margin-right: -9px;
    margin-top: -9px;
    background: rgb(228, 87, 87);
}
@media (max-width: 768px) {
    .boxes {
        margin-top: 6px;
        margin-bottom: 7px;
    }
    .main-wrapper {
        padding: 0px !important;
    }
    .preview-box {
        display: none;
    }
}

.number-reverse {
  background: #000000;
  color: white;
  border-radius: 5px;
  /* font-size: 20px; */
  font-weight: bold;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 2px solid transparent;
}

.number-reverse.active {
  background: rgb(125, 225, 135);
  border: 2px solid black;
  color: black;
}

</style>
