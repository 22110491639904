<template>
    <div class="p-3 pt-4" style="background-image: url('https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/1c2e5ac4-c359-4940-f18c-3d029d46e100/public'); height: 100vh; overflow-y: scroll; background-size: cover;">
        <div style="height: 130vh;">
            <table class="col-12 table-bordered" style="color: #0050A7">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Name</th>
                        <!-- <th>Won Amount</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(close, index) in closedDayList" :key="index">
                        <td>{{close.date}}</td>
                        <td>{{close.name}}</td>
                        <!-- <td>{{Number(user.won).toLocaleString()}} MMK</td> -->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      closedDayList: []
    };
  },
  methods: {
    async closedDays(date) {
        try {
        const res = await axios.get('https://api.2dboss.com/api/lv/holiday_list');
        // console.log(res.data.data)
        this.closedDayList = res.data.data;
        } catch (error) {
        console.log(error);
        }
    },
  },
  mounted() {
    this.closedDays();
  },
};
</script>
<style scoped>

table {
        width: 100%;
        border-collapse: collapse;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(0,0,0,0.1);
    }

    th,
    td {
        padding: 10px !important;
        background-color: rgba(255,255,255,0.2);
        color: #0050A7;
    }

    th {
        text-align: left;
    }

    thead {
        th {
            background: #0050A7;
            color: white;
        }
    }

    tbody {
        tr {
            &:hover {
                background-color: rgba(95, 111, 180, 0.3);
            }
        }
        td {
            position: relative;
            &:hover {
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -9999px;
                    bottom: -9999px;
                    background-color: rgba(255,255,255,0.2);
                    z-index: -1;
                }
            }
        }
    }
</style>