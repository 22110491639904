<template>
    <div class="container-fluid d-flex flex-wrap mt-5 main-wrapper" style="height: 100vh; overflow-y: scroll; background-size: cover; padding-bottom: 15rem !important; background-attachment: fixed;">
        <!-- <div class="col-lg-3 col-12 pl-0 side-nav-bar-custom">
            <SideNav />
        </div> -->
        <div class="col-lg-9 col-12 p-0 mb-5">
            <div class="bg-transparent">
                <div class="card-header bg-transparent">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h3 class="mb-0" style="color: #000000 !important;">
                                {{$t('withdraw.withdraw_form')}}
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="card-body bg-transparent">
                    <form>
                        <h6 class="heading-small text-muted" style="color: #000000 !important;">
                            {{$t('withdraw.account_information')}}
                        </h6>
                        <span class="heading-small">{{$t('withdraw.user_balance')}} - </span>
                        <span>
                          <b class="text-danger">{{ Number($store.state.amount).toLocaleString() }}</b>
                          MMK
                        </span>

                        <div class="pl-lg-4 mt-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label
                                            class="form-control-label"
                                            for="input-username"
                                            style="color: #000000 !important;"
                                            >{{$t('withdraw.choose_payment_type')}}</label
                                        >
                                        <AccountSelect
                                            :data="selected_account"
                                            field="account_id"
                                            :accounts="accounts"
                                        />
                                        <span class="text-danger">{{
                                            errors.account
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-lg-6">
                          <div class="form-group">
                              <label class="form-control-label" style="color: #000000 !important;">{{
                                  $store.state.language === "en"
                                      ? "QR Code"
                                      : "QR Code"
                              }}</label>

                              <div class="custom-file d-flex">
                                  <van-uploader
                                      class="mx-auto"
                                      v-model="imageUploader"
                                      :max-count="1"
                                  />
                              </div>
                              <span class="text-danger">{{
                                  errors.image
                              }}</span>
                          </div>
                        </div> -->

                        <hr class="my-4" />
                        <div class="pl-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label
                                            class="form-control-label"
                                            for="input-email"
                                            style="color: #000000 !important;"
                                            >{{$t('withdraw.account_number')}}</label
                                        >
                                        <input
                                            type="number"
                                            class="form-control"
                                            v-model="
                                                withdrawData.account_number
                                            "
                                        />
                                        <span class="text-danger">{{
                                            errors.number
                                        }}</span>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="form-control-label" style="color: #000000 !important;">{{$t('withdraw.enter_amount')}}</label>
                                        <span class="text-danger text-xs"> {{$t('withdraw.minimum_deposit')}}</span>
                                        <input
                                            type="number"
                                            id="input-email"
                                            class="form-control"
                                            v-model="withdrawData.amount"
                                        />
                                        <span class="text-danger">{{
                                            errors.amount
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pl-lg-4">
                            <div class="form-group">
                                <label class="form-control-label" style="color: #000000 !important;">{{$t('withdraw.remark')}}</label>
                                <textarea
                                    rows="4"
                                    class="form-control"
                                    v-model="withdrawData.remark"
                                ></textarea>
                            </div>
                            <div class="form-group">
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    @click="$playSound(); sendWithdrawData()"
                                >
                                    <div v-if="!isConfirm" class="px-4">
                                        {{$t('withdraw.submit')}}
                                    </div>
                                    <Loading
                                        color="#ffffff"
                                        v-if="isConfirm"
                                        size="24"
                                        class="px-4 py-0"
                                    />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast, Dialog, Loading } from 'vant';
import { mapActions } from 'vuex';
import AccountSelect from './components/AccountSelect.vue';

export default {
  components: { AccountSelect, Loading },
  data() {
    return {
      imageUploader: [],
      selected_account: {
        account_id: null,
      },
      accounts: [
        {
          name: 'KBZ pay',
          id: 'KBZ pay',
          link: 'https://cdn.gold549.com/build/assets/img/payments/kpay.png',
        },
        {
          name: 'KBZ Mobile Banking',
          id: 'KBZ Mobile Banking',
          link: 'https://cdn.gold549.com/build/assets/img/payments/kbz.png',
        },
        {
          name: 'Wave pay',
          id: 'Wave pay',
          link: 'https://cdn.gold549.com/build/assets/img/payments/wavepay.png',
        },
        {
          name: 'CB Pay',
          id: 'CB Pay',
          link: 'https://cdn.gold549.com/build/assets/img/payments/cb_pay.png',
        },
        {
          name: 'CB Banking',
          id: 'CB Banking',
          link: 'https://cdn.gold549.com/build/assets/img/payments/cbbank.png',
        },
        {
          name: 'AYA Pay',
          id: 'AYA Pay',
          link: 'https://cdn.gold549.com/build/assets/img/payments/ayapay.png',
        },
        {
          name: 'AYA Banking',
          id: 'AYA Banking',
          link: 'https://cdn.gold549.com/build/assets/img/payments/aya.png',
        },

      ],
      account: [],
      imageUploader: [],
      withdrawData: {},
      errors: {},
      isConfirm: false,
    };
  },
  methods: {
    ...mapActions(['fetchUser']),
    async sendWithdrawData() {
      try {
        const authUser = await this.fetchUser();
        this.errors = {};
        if (!this.selected_account.account_id) {
          this.errors.account = this.$t('withdraw.enter_account_type');
          return false;
        }
        if (!this.withdrawData.account_number) {
          this.errors.number = this.$t('withdraw.enter_account_number');
          return false;
        }
        if (!this.withdrawData.amount) {
          this.errors.amount = this.$t('withdraw.enter_amount');;
          return false;
        }
        if (this.withdrawData.amount < 10000) {
          this.errors.amount = this.$t('withdraw.enter_at_least');
          return false;
        }
        if (this.withdrawData.amount > parseInt(authUser.amount)) {
          const balanceError = this.$t('withdraw.insufficient_balance');
          Dialog.alert({
            message: balanceError,
            confirmButtonText: this.$t('withdraw.go_back'),
          }).then(() => {
            // on close
          });
          return false;
        }
        if (this.isConfirm) {
          return false;
        }
        this.$store.commit(
          'setAmount',
          (this.$store.state.amount -= this.withdrawData.amount),
        );
        this.isConfirm = true;
        if (this.imageUploader[0]) {
          this.withdrawData.transaction_image = this.imageUploader[0].content;
        }
        this.withdrawData.type = this.selected_account.account_id;
        this.withdrawData.date = this.currentTime;
        const res = await axios.post('/withdraws', this.withdrawData, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              });
        const resUser = await axios.post(
          `/admin/user/${this.$store.state.authUser.id}`,
          {
            amount: this.withdrawData.amount,
            type: 'withdraw',
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
          }
        );
        this.imageUploader = [];
        this.withdrawData = {};
        this.selected_account.account_id = null;
        Toast.success('Success');
        this.isConfirm = false;
      } catch (error) {
        this.isConfirm = false;
      }
      return true;
    },
    createDateWithTimeZone() {
      const moment = require('moment-timezone');
      const dateInTimeZone = moment.tz(new Date(), 'Asia/Rangoon');
      // Extract individual components from moment object
      const year = dateInTimeZone.year();
      const month = dateInTimeZone.month();
      const day = dateInTimeZone.date();
      const hour = dateInTimeZone.hour();
      const minute = dateInTimeZone.minute();
      const second = dateInTimeZone.second();
      const millisecond = dateInTimeZone.millisecond();

      // Create a new Date object with the extracted components
      const newDateObject = new Date(year, month, day, hour, minute, second, millisecond);
      return newDateObject;
    },
  },
  //   watch: {
  //     'selected_account.account_id': function () {
  //       this.account = this.accounts.filter(
  //         (account) => account.id === this.selected_account.account_id,
  //       );
  //     },
  //   },
  computed: {
    currentTime() {
      return moment(this.createDateWithTimeZone()).format('lll');
    },
  },
  async mounted() {
    const authUser = await this.fetchUser();
    if (!authUser) {
      this.$router.push('/auth/login');
    }
  },
};
</script>
<style scoped>
.btn{
  background: #0050A7 !important;
}
.image-container {
    width: 200px;
    height: 200px;
}
.image-container img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.number-container {
    border-radius: 5px;
    background: #e9ecef;
    justify-content: center;
}
.custom-file {
    height: auto;
}
@media (max-width: 768px) {
    .main-wrapper {
        padding: 7px !important;
    }
}
</style>
