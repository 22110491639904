import { render, staticRenderFns } from "./3d.vue?vue&type=template&id=6ff33e56&scoped=true"
import script from "./3d.vue?vue&type=script&lang=js"
export * from "./3d.vue?vue&type=script&lang=js"
import style0 from "./3d.vue?vue&type=style&index=0&id=6ff33e56&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ff33e56",
  null
  
)

export default component.exports