<template>
    <div style="height: 100vh; overflow-y: scroll; padding-bottom: 8rem;">
       <div class="form-group pt-4 d-flex justify-content-center px-2" style="width: 100%">
            <div>
                <input v-model="name" type="text" class="game-search" :placeholder="$t('voucher.search')">
            </div>
        </div>
        <div class="p-0 pb-5 px-2" style="width: 100%; gap: 10px; display: grid; grid-template-columns: 1fr 1fr 1fr;">
            <div
                class="game-card p-0"
                v-for="(game, index) in newDreams.length > 0 ? newDreams : dreams"
                :key="index"
                @click="gameInit(game)"
            >
            <div class="d-flex justify-content-between px-3 py-2" style="background: linear-gradient(to right, #0050A7, #0050A7); border-top-left-radius: 7px; border-top-right-radius: 7px;">
              <span class="text-lg text-white">{{ game.num_1 }}</span>
              <span class="text-lg text-white">{{ game.num_2 }}</span>
            </div>
              <div :class="$route.query.type === '2d' ? 'my-3' : ''">
                <img
                      :src="linkGenerate(game.dreamBookId)"
                      alt=""
                      style="width: 100%; height: 100%;"
                  />
              </div>
              <div class="d-flex justify-content-between text-center px-2 py-2" style="background: linear-gradient(to right, #0050A7, #0050A7); border-bottom-left-radius: 7px; border-bottom-right-radius: 7px;">
                <span class="text-md text-white mx-auto" v-if="$i18n.locale === 'en'">{{ game.title_en }}</span>
                <span class="text-md text-white mx-auto" v-if="$i18n.locale === 'mm'">{{ game.title_mm }}</span>
                <span class="text-md text-white mx-auto" v-if="$i18n.locale === 'th'">{{ game.title_th }}</span>
                <span class="text-md text-white mx-auto" v-if="$i18n.locale === 'cn'">{{ game.title_zh }}</span>
              </div>
                
            </div>
        </div>
        
        <van-overlay :show="isLoading">
            <div class="wrapper" @click.stop>
              <van-loading type="spinner" color="#ffffff" class="mx-auto"/>
            </div>
        </van-overlay>
    </div>
</template>
<script>
// import { DropdownMenu, DropdownItem } from 'vant';
import { mapActions } from 'vuex';

export default {
//   components: { DropdownMenu, DropdownItem },

  data() {
    return {
      isLoading: false,
      dreams: [],
      name: null,
      newDreams: [],
    };
  },
  methods: {
    ...mapActions(['fetchUser']),
    linkGenerate(id){
      if(this.$route.query.type === '2d') {
        return 'https://cdn.gold549.com/build/assets/img/dreamBook/twod_dreamBook_'+id+'.png';
      } else {
        return 'https://cdn.gold549.com/build/assets/img/dreamBook/threed_dreamBook_'+id+'.png';
      }
    },  
    async fetchDreams() {
      try {
        if(this.$route.query.type === '2d') {
          const res = await axios.get('/dreamBook/2d');
          this.dreams = res.data.data;
        } else {
          const res = await axios.get('/dreamBook/3d');
          this.dreams = res.data.data;
        }
        // const res = await axios.get('/dreamBook/2d');
        // this.dreams = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    name() {
      this.newDreams = this.dreams.filter((game) => game.title_mm.toLowerCase().includes(this.name.toLowerCase()));
    },
  },
  async mounted() {
    const authUser = await this.fetchUser();
    if (!authUser) {
      this.$router.push('/auth/login');
    }
    this.fetchDreams();
  },
};
</script>
<style scoped>
.provider-dropdown {
    width: 200px;
    height: 40px;
}

.game-search {
    width: 230px !important;
    height: 40px;
    border: 1px solid #0050A7;
    background: transparent;
    color: white;
    padding: 0 10px;
    border-radius: 5px;
}

.game-search:active, .game-search:focus {
    width: 230px !important;
    height: 40px;
    border: 1px solid #0050A7;
    background: transparent;
    color: black;
}

/* .game-search:active, .game-search:focus {
    border: 1px solid #000 !important;
} */

.game-card {
    background-color: white !important;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    flex: 1 1 calc(33% - 10px);
    box-sizing: border-box;
    border-radius: 7px;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
</style>
