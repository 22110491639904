<template>
    <div class="container-fluid d-flex mt-5 flex-wrap main-wrapper" style="height: 100vh; overflow-y: scroll; background-size: cover; padding-bottom: 15rem; background-attachment: fixed;">
        <!-- <div class="col-lg-3 col-12 pl-0 side-nav-bar-custom">
            <SideNav />
        </div> -->
        <div class="col-lg-9 col-12 p-0 mb-5 bg-transparent" style="height: 150vh;">
            <div class="bg-transparent">
                <div class="card-header bg-transparent">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h3 class="mb-0" style="color: #000000;">
                                {{$t('deposit.deposit_form')}}
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="card-body bg-transparent">
                    <form>
                        <h6 class="heading-small" style="color: #000000 !important;">
                            {{$t('deposit.account_information')}}
                        </h6>
                        <span class="heading-small">{{$t('deposit.user_balance')}} - </span>
                        <span>
                          <b class="text-danger">{{ Number($store.state.amount).toLocaleString() }}</b>
                         MMK
                        </span>
                        <div class="pl-lg-4 mt-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label
                                            class="form-control-label"
                                            for="input-username"
                                            style="color: #000000 !important;"
                                            >{{$t('deposit.choose_payment_type')}}</label
                                        >
                                        <AccountSelect
                                            :data="selected_account"
                                            field="account_id"
                                            :accounts="accounts"
                                        />
                                        <span class="text-danger">{{
                                            errors.account
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-12 p-0 d-flex flex-wrap"
                                v-if="account.length !== 0"
                            >
                                <div class="col-lg-6 col-12 py-3">
                                    <h3>{{ account[0].name }}</h3>
                                    <div
                                        class="
                                            number-container
                                            d-flex
                                            py-3
                                            my-2
                                        "
                                    >
                                        {{$t('deposit.account_name')}}
                                        :
                                        <h3 class="m-0">
                                            &nbsp;{{ account[0].account_name }}

                                        </h3>
                                    </div>
                                    <div
                                        class="
                                            number-container
                                            d-flex
                                            py-3
                                            my-2
                                        "
                                    >
                                        {{$t('deposit.account_number')}}
                                        :
                                        <h3 class="m-0" id="textToCopy">
                                            &nbsp;{{ account[0].number }}

                                        </h3>
                                        <span class="ml-5 text-danger badge badge-danger" @click="copyText">Copy</span>
                                    </div>
                                    <!-- <b class="text-sm">{{$t('deposit.account_remark')}}</b> -->
                                </div>
                            </div>
                        </div>
                        <hr class="my-4" />
                        <div class="pl-lg-4">
                            <div class="row">
                                <!-- <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="form-control-label" style="color: #000000 !important;">{{$t('deposit.screenshot')}}</label>

                                        <div class="custom-file d-flex">
                                            <van-uploader
                                                class="mx-auto"
                                                v-model="imageUploader"
                                                :max-count="1"
                                            />
                                        </div>
                                        <span class="text-danger">{{
                                            errors.image
                                        }}</span>
                                    </div>
                                </div> -->
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label
                                            class="form-control-label"
                                            for="input-email"
                                            style="color: #000000 !important;"
                                            >{{$t('deposit.amount')}} </label
                                        >
                                        <span class="text-danger text-xs"> {{$t('deposit.minimum_deposit')}}</span>
                                        <input
                                            type="number"
                                            id="input-email"
                                            class="form-control"
                                            v-model="depositData.amount"
                                        />
                                        <span class="text-danger">{{
                                            errors.amount
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pl-lg-4">
                            <div class="form-group">
                                <label class="form-control-label" style="color: #000000 !important;">{{
                                    $store.state.language === "en"
                                        ? "Last Transaction Id 6"
                                        : "လုပ်ဆောင်မှုအမှတ်စဉ် (နောက်ဆုံး ၆ လုံး)"
                                }}</label>
                                <input
                                    type="number"
                                    id="input-email"
                                    class="form-control"
                                    v-model="depositData.remark"
                                />
                                <span class="text-danger">{{
                                    errors.remark
                                }}</span>

                            </div>
                            <div class="form-group">
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    @click="$playSound(); sendDepositData()"
                                >
                                    <div v-if="!isConfirm" class="px-4">
                                        {{$t('deposit.submit')}}
                                    </div>

                                    <Loading
                                        color="#ffffff"
                                        v-if="isConfirm"
                                        size="24"
                                        class="px-4 py-0"
                                    />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast, Loading } from 'vant';
import { mapActions } from 'vuex';
import AccountSelect from './components/AccountSelect.vue';

export default {
  components: { AccountSelect, Loading },
  data() {
    return {
      selected_account: {},
      accounts: [],
      account: [],
      imageUploader: [],
      depositData: {},
      errors: {},
      isConfirm: false,
    };
  },
  methods: {
    ...mapActions(['fetchUser']),
    copyText() {
      // Get the text to copy
      const textToCopy = document.getElementById('textToCopy').innerText;

      // Create a temporary textarea element
      const textarea = document.createElement('textarea');
      textarea.value = textToCopy;

      // Append the textarea to the DOM
      document.body.appendChild(textarea);

      // Select the text in the textarea
      textarea.select();

      // Execute the 'copy' command
      document.execCommand('copy');

      // Remove the temporary textarea
      document.body.removeChild(textarea);

      alert('You have copy Phone Number');
    },
    async fetchAccounts() {
      try {
        const res = await axios.get('/accounts', {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              });
        this.accounts = res.data.data;

        // Group objects by name
        // const groupedByName = this.accounts.reduce((acc, obj) => {
        //     acc[obj.name] = acc[obj.name] || [];
        //     acc[obj.name].push(obj);
        //     return acc;
        // }, {});

        // // Get a random object from each group
        // const getRandomObjectFromGroup = (group) => {
        //     const randomIndex = Math.floor(Math.random() * group.length);
        //     return group[randomIndex];
        // };

        // // Collect the random objects into a single array
        // this.accounts = Object.keys(groupedByName).map(name => getRandomObjectFromGroup(groupedByName[name]));

      } catch (error) {
        console.log(error);
      }
    },
    async sendDepositData() {
      try {
        const authUser = await this.fetchUser();
        this.errors = {};
        if (!this.selected_account.account_id) {
          this.errors.account = this.$t('deposit.enter_account_type');
          return false;
        }
        // if (!this.imageUploader.length > 0) {
        //   this.errors.image =  this.$t('deposit.enter_screenshot_photo');
        //   return false;
        // }
        if (!this.depositData.amount) {
          this.errors.amount = this.$t('deposit.enter_amount');
          return false;
        }
        if (this.depositData.amount < 3000) {
          this.errors.amount = this.$t('deposit.enter_at_least');
          return false;
        }
        if (!this.depositData.remark || this.depositData.remark.length < 5) {
          this.errors.remark = this.$store.state.language === 'en'
            ? 'Please enter last transaction id 5'
            : 'Please enter last transaction id 5';
          return false;
        }
        this.isConfirm = true;
        // if (this.imageUploader[0]) {
        //   this.depositData.transaction_image = this.imageUploader[0].content;
        // }
        this.depositData.account_id = this.account[0].id;
        this.depositData.date = this.currentTime;
        const res = await axios.post('/deposits', this.depositData, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              });
        this.imageUploader = [];
        this.depositData = {};
        this.selected_account.account_id = null;
        Toast.success(
          this.$store.state.language === 'en'
            ? 'Success'
            : 'အောင်မြင်သည်။',
        );
        this.isConfirm = false;
      } catch (error) {
        Toast.fail(
          this.$store.state.language === 'en' ? 'Fail' : 'ကျရှုံးသည်။',
        );
        this.isConfirm = false;
      }
      return true;
    },
    createDateWithTimeZone() {
      const moment = require('moment-timezone');
      const dateInTimeZone = moment.tz(new Date(), 'Asia/Rangoon');
      // Extract individual components from moment object
      const year = dateInTimeZone.year();
      const month = dateInTimeZone.month();
      const day = dateInTimeZone.date();
      const hour = dateInTimeZone.hour();
      const minute = dateInTimeZone.minute();
      const second = dateInTimeZone.second();
      const millisecond = dateInTimeZone.millisecond();

      // Create a new Date object with the extracted components
      const newDateObject = new Date(year, month, day, hour, minute, second, millisecond);
      return newDateObject;
    },
  },
  watch: {
    'selected_account.account_id': function () {
      this.account = this.accounts.filter(
        (account) => account.id === this.selected_account.account_id,
      );
    },
  },
  computed: {
    currentTime() {
      return moment(this.createDateWithTimeZone()).format('lll');
    },
  },
  async mounted() {
    this.fetchAccounts();
    const authUser = await this.fetchUser();
    if (!authUser) {
      this.$router.push('/auth/login');
    }
  },
};
</script>
<style scoped>
.btn{
  background: #0050A7 !important;
}
.image-container {
    width: 200px;
    height: 200px;
}
.image-container img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.number-container {
    border-radius: 5px;
    background: #e9ecef;
    justify-content: center;
}
.custom-file {
    height: auto;
}
@media (max-width: 768px) {
    .main-wrapper {
        padding: 7px !important;
    }
}
</style>
